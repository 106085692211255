import React from "react";
import "./App.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import logo from "./assets/images/logo.png";
import logoWebp from "./assets/images/logo.webp";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Hidden from "@material-ui/core/Hidden";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import FaceIcon from "@material-ui/icons/Face";
import ChildCareIcon from "@material-ui/icons/ChildCare";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import WorkIcon from "@material-ui/icons/Work";
import HomeIcon from "@material-ui/icons/Home";
import { Link, Fab } from "@material-ui/core";
import { useEffect } from "react";
import Loading from "./loading";
/*import Banner from "./banner";
import ImageGrid from "./imageGrid";
import WhatIDo from "./whatIDo";
import AboutMe from "./aboutMe";
import OurMap from "./ourMap";
import Footer from "./footer";*/
import ReactGA from "react-ga";
ReactGA.initialize("UA-162482866-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#69DBD9",
    },
  },
});
const useStyles = makeStyles((theme) => ({
  buttonBold: {
    fontWeight: "bold",
    padding: 15,
  },
  navButtonsDiv: {
    position: "absolute",
    right: 20,
  },
  foot: {
    backgroundColor: "green",
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "200px",
  },
  bottomNav: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    zIndex: 101,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  progressIndicator: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    marginTop: 76,
  },
}));

const useActionButtons = makeStyles({
  root: {
    color: "#676e75",
    "&$selected": {
      color: "#69DBD9",
    },
  },
  selected: {},
});

function App() {
  const classes = useStyles();
  const bottomActionClasses = useActionButtons();
  const [value, setValue] = React.useState(-1);

  const listener = (e) => {
    setValue(-1);
  };

  const sectionsIds = [
    "areas-atuacao",
    "psicoterapia-infantil",
    "home",
    "sobre",
    "localizacao-mapa",
  ];

  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  },[]);
  const Banner = React.lazy(() => import("./banner"));
  const ImageGrid = React.lazy(() => import("./imageGrid"));
  const WhatIDo = React.lazy(() => import("./whatIDo"));
  const AboutMe = React.lazy(() => import("./aboutMe"));
  const OurMap = React.lazy(() => import("./ourMap"));
  const Footer = React.lazy(() => import("./footer"));
  return (
    <ThemeProvider theme={theme}>
      <main>
        <Hidden only="xs">
          <AppBar position="fixed" color="primary">
            <Toolbar color="white">
              <div className="center-logo">
                <Link href="#inicio">
                  <picture>
                    <source srcset={logoWebp} type="image/webp" />
                    <img
                      src={logo}
                      style={{ height: 70 }}
                      alt="VCB: Atendimento Psicológico"
                    />
                  </picture>
                </Link>
              </div>
              <div
                className={[classes.navButtonsDiv, "navbar-resize"].join(" ")}
              >
                <Button
                  color={"secondary"}
                  className={classes.buttonBold}
                  href="#areas-atuacao"
                >
                  Áreas de Atuação
                </Button>
                <Button
                  color={"secondary"}
                  className={classes.buttonBold}
                  href="#psicoterapia-infantil"
                >
                  Psicoterapia Infantil
                </Button>
                <Button
                  color={"secondary"}
                  className={classes.buttonBold}
                  href="#sobre"
                >
                  Sobre Mim
                </Button>
                <Button
                  color={"secondary"}
                  className={classes.buttonBold}
                  href="#localizacao-mapa"
                >
                  Localização
                </Button>
              </div>
            </Toolbar>
          </AppBar>
        </Hidden>
        <Hidden mdUp>
          <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
              //setValue(newValue);
              document.getElementById(sectionsIds[newValue]).scrollIntoView({
                behavior: "smooth",
              });
            }}
            showLabels
            className={classes.bottomNav}
          >
            <BottomNavigationAction
              classes={bottomActionClasses}
              label="Áreas"
              icon={<WorkIcon />}
            />
            <BottomNavigationAction
              classes={bottomActionClasses}
              label="Infantil"
              icon={<ChildCareIcon />}
            />
            <Fab
              onClick={(event, newValue) => {
                //setValue(-1);
                document.getElementById("inicio").scrollIntoView({
                  behavior: "smooth",
                });
              }}
              color="secondary"
              aria-label="home"
              className={classes.fabButton}
            >
              <HomeIcon />
            </Fab>
            <BottomNavigationAction
              classes={bottomActionClasses}
              label="Sobre Mim"
              icon={<FaceIcon />}
            />
            <BottomNavigationAction
              classes={bottomActionClasses}
              label="Contato"
              icon={<LocationOnIcon />}
            />
          </BottomNavigation>
        </Hidden>
        <React.Suspense fallback={<Loading />}>
          <Banner />
        </React.Suspense>
        <React.Suspense fallback={<div />}>
          <ImageGrid />
        </React.Suspense>
        <React.Suspense fallback={<div />}>
          <WhatIDo />
        </React.Suspense>
        <React.Suspense fallback={<div />}>
          <AboutMe />
        </React.Suspense>
        <React.Suspense fallback={<div />}>
          <OurMap />
        </React.Suspense>
        <React.Suspense fallback={<div />}>
          <Footer />
        </React.Suspense>
      </main>
    </ThemeProvider>
  );
}

export default App;
