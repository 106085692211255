import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  loadingDiv: {
    width: '100%',
    minHeight: 'calc(100vh - 178px - 108px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));
export default function Loading() {
  const classes = useStyles();
  return <div className={classes.loadingDiv}>
    <CircularProgress color="secondary" />
  </div>
}